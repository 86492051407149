import axios from "axios";
// import mixpanel from "mixpanel-browser";
import { AnalyticsEventData, AnalyticsEventName } from "@/types";
import { API_URL } from "@/constants";

const track = async (
  eventName: AnalyticsEventName,
  eventData?: AnalyticsEventData,
) => {
  // send event to mixpanel (could be blocked by user browser)
  // FIX: spreading eventData as mixpanel.track mutates the object to add a token property
  // TODO: mixpanel when deployed is not working after move to app directory
  // mixpanel.track(eventName, { ...eventData });

  // send event to api
  try {
    await axios.post(`${API_URL}/graphql`, {
      // this query already exists in queries.ts but need to send sting to axios not gql version
      query: `
        mutation SEND_ANALYTICS_MUTATION($input: AnalyticsInput) {
          sendAnalytics(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          eventName,
          eventData,
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export default track;
