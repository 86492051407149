"use client";

import { useEffect, ReactNode, useCallback, RefObject } from "react";
import { usePathname } from "next/navigation";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import Modal from "react-modal";
import cn from "@/utils/cn";

Modal.setAppElement("body");

type GenericModalProps = {
  isOpen: boolean;
  center?: boolean;
  contentLabel: string;
  close: () => void;
  children: ReactNode;
  className?: string;
  overlayClassName?: string;
  mountedRef?: RefObject<boolean>;
  updateModalMountedRef?: (value: boolean) => void;
};

const GenericModal = ({
  isOpen = false,
  center,
  contentLabel,
  close,
  children,
  className,
  overlayClassName,
  mountedRef,
  updateModalMountedRef = () => null,
}: GenericModalProps) => {
  const pathname = usePathname();

  const handleClose = () => {
    if (mountedRef) {
      // eslint-disable-next-line no-param-reassign
      updateModalMountedRef(false);
    }

    close();
  };

  const modalEl = useCallback(
    (node) => {
      if (node) {
        if (isOpen) {
          disableBodyScroll(node);
        } else {
          enableBodyScroll(node);
        }
      }
    },
    [isOpen],
  );

  useEffect(() => {
    if (!isOpen) return;

    if (!mountedRef || !mountedRef.current) {
      updateModalMountedRef(true);
      return;
    }

    close();
  }, [pathname, isOpen, close, mountedRef, updateModalMountedRef]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    <Modal
      overlayClassName={cn(
        "fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex flex-col justify-center items-center p-6 z-10",
        {
          "md:justify-start": !center,
          [overlayClassName as string]: !!overlayClassName,
        },
      )}
      className={cn(
        "flex p-5 bg-white rounded-lg outline-0 w-full max-h-full md:max-w-[640px] md:min-h-[200px] md:max-h-[921px] ",
        {
          [className as string]: !!className,
        },
      )}
      isOpen={isOpen}
      contentLabel={contentLabel}
      onRequestClose={handleClose}
    >
      <div className="w-full overflow-y-auto" ref={modalEl}>
        {children}
      </div>
    </Modal>
  );
};

export default GenericModal;
