import { SocialPlatform } from "@prisma/client";

export const SPARKS_LIST_URL_PATTERN =
  /^https?(.*?)\/sparks[^/]\??(.*?)$|^https?(.*?)\/sparks$/;
export const SPARKS_INFINITE_LIST_URL_PATTERN =
  /^(\$inf\$)https?(.*?)\/sparks[^/]\??(.*?)$|^(\$inf\$)https?(.*?)\/sparks$/;
export const CLUSTERS_LIST_URL_PATTERN =
  /^https?(.*?)\/clusters[^/]\??(.*?)$|^https?(.*?)\/clusters$/;
export const CLUSTERS_INFINITE_LIST_URL_PATTERN =
  /^(\$inf\$)https?(.*?)\/clusters[^/]\??(.*?)$|^(\$inf\$)https?(.*?)\/clusters$/;

export const ADD_NEW_SPARK = "ADD_NEW_SPARK";
export const VIEW_SPARK_CLUSTER = "VIEW_SPARK_CLUSTER";
export const VIEW_CONNECTED_SPARK = "VIEW_CONNECTED_SPARK";
export const VIEW_SPARK_FROM_UPDATE = "VIEW_SPARK_FROM_UPDATE";
export const VIEW_CLUSTER_FROM_UPDATE = "VIEW_CLUSTER_FROM_UPDATE";
export const SWITCH_TO_USER_FOR_PROCESSING = "SWITCH_TO_USER_FOR_PROCESSING";
export const REMOVE_SPARK_FROM_CLUSTER = "REMOVE_SPARK_FROM_CLUSTER";
export const CREATE_CLUSTER = "CREATE_CLUSTER";
export const DELETE_CLUSTER = "DELETE_CLUSTER";
export const DELETE_SPARK = "DELETE_SPARK";
export const EDIT_SPARK = "EDIT_SPARK";
export const CLICK_LINK_TO_ORIGINAL_RESOURCE =
  "CLICK_LINK_TO_ORIGINAL_RESOURCE";
export const EDIT_CLUSTER = "EDIT_CLUSTER";
export const FOLLOW_CLUSTER = "FOLLOW_CLUSTER";
export const UNFOLLOW_CLUSTER = "UNFOLLOW_CLUSTER";
export const LOGOUT = "LOGOUT";
export const CLICK_HELP = "CLICK_HELP";
export const CLICK_YOUR_SUBSCRIPTION = "CLICK_YOUR_SUBSCRIPTION";
export const CLICK_IOS_APP = "CLICK_IOS_APP";
export const CLICK_BROWSER_EXTENSION = "CLICK_BROWSER_EXTENSION";
export const CLICK_COMMUNITY = "CLICK_COMMUNITY";
export const ADD_SPARK_TO_CLUSTER = "ADD_SPARK_TO_CLUSTER";
export const ADD_CONNECTION = "ADD_CONNECTION";
export const CHANGE_SPARK_SHARE_SETTING = "CHANGE_SPARK_SHARE_SETTING";
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const REMOVE_CONNECTION = "REMOVE_CONNECTION";
export const VIEW_CLUSTERS = "VIEW_CLUSTERS";
export const VIEW_SPARKS = "VIEW_SPARKS";
export const VIEW_UPDATES = "VIEW_UPDATES";
export const VIEW_CLUSTER = "VIEW_CLUSTER";
export const VIEW_SPARK = "VIEW_SPARK";

export const TIPS = {
  spark: [
    {
      heading: "Some tips",
      tips: [
        "Articulate your insight or learning in your own words",
        "1 spark = 1 idea, keep it simple",
        "Add other content in 'quote marks' to easily identify later",
        "Big spark note? Add a 'title' sentence first for easy scanning later",
      ],
    },
    {
      heading: "Spark from anywhere",
      tips: [
        "Add a link from the web or social media",
        "Add the details of a book or audiobook",
        "Select 'Nothing' when there's no context for your spark",
      ],
    },
  ],
  cluster: [
    {
      smallHeading: "You haven't added a summary to this cluster yet",
      tips: [
        "Hit 'Edit' above to summarise your learning in this topic cluster, or you can <a href='http://help.sparksapp.io/en/articles/5084982-cluster-summaries' target='_blank' rel='noreferrer'>find out more about summaries here.</a>",
      ],
    },
  ],
  editSpark: [
    {
      heading: "Some tips",
      tips: [
        "Articulate your insight or learning in your own words",
        "1 spark = 1 idea, keep it simple",
        "Add other content in 'quote marks' to easily identify later",
        "Big spark note? Add a 'title' sentence first for easy scanning later",
      ],
    },
    {
      heading: "Spark from anywhere",
      tips: [
        "Add a link from the web or social media",
        "Add the details of a book or audiobook",
        "Select 'Nothing' when there's no context for your spark",
      ],
    },
  ],
  addCluster: [
    {
      heading: "Clusters are useful for",
      tips: [
        "Gathering all sparks by a learning goal",
        "Trying to make sense of a topic of interest",
        "Writing/creating something like a document, paper or presentation",
        "Just categorising sparks for later browsing",
        "Check out our Help section to <a href='http://help.sparksapp.io/en/collections/2864828-connections-and-clusters' target='_blank' rel='noreferrer'>find out more about clusters</a>",
      ],
    },
    {
      heading: "Some naming tips",
      tips: [
        "Base it on the knowledge area you are sparking about (eg.'Psychology of learning') or maybe a personal goal (e.g. 'Blog article about Healthy Eating').",
        "You can add an * at the beginning to make it appear first in the list of clusters - great for very current clusters, and you can always rename later",
        "Try to use your own normal vocabulary",
      ],
    },
  ],
};

export const CARDS_LIMIT = 25;

export enum ResourceCategory {
  PODCAST_EPISODE = "PODCAST_EPISODE",
  AUDIOBOOK = "AUDIOBOOK",
  VIDEO = "VIDEO",
  SOCIAL = "SOCIAL",
  BOOK = "BOOK",
  WEBSITE = "WEBSITE",
}

export const SPARKS_FILTERS = [
  {
    name: "show",
    defaultOption: 0,
    options: [
      { value: "all", label: "All Sparks" },
      { value: "withNotes", label: "Sparks with notes" },
      { value: "blank", label: "Blank sparks" },
    ],
  },
  {
    name: "state",
    defaultOption: 0,
    options: [
      { value: "all", label: "Connected or not" },
      { value: "withConnections", label: "With connections" },
      { value: "withNoConnections", label: "With no connections" },
      { value: "notInACluster", label: "Not in a cluster" },
    ],
  },
  {
    name: "date",
    defaultOption: 3,
    options: [
      { value: "fromTheLastWeek", label: "From the last week" },
      { value: "fromTheLastMonth", label: "From the last month" },
      { value: "fromTheLastSixMonths", label: "From the last 6 months" },
      { value: "all", label: "Since you joined" },
    ],
  },
];

export const CLUSTERS_FILTERS = [
  {
    name: "show",
    defaultOption: 0,
    options: [
      { value: "all", label: "All shared clusters" },
      { value: "sharedWithMe", label: "Clusters shared with me" },
      { value: "sharedByMe", label: "Clusters shared by me" },
    ],
  },
];

export const USER_ACCESS_PATHS = [
  "/sign-up",
  "/sign-in",
  "/welcome",
  "/request-reset-password",
  "/reset-password",
];

export const CONNECTIONS_DEPTH_LIMIT = 3;

export const SOCIAL_PLATFORMS: { name: SocialPlatform; url: RegExp }[] = [
  {
    name: SocialPlatform.FACEBOOK,
    url: /facebook\.com/,
  },
  {
    name: SocialPlatform.TWITTER,
    url: /twitter\.com/,
  },
  {
    name: SocialPlatform.INSTAGRAM,
    url: /instagram\.com/,
  },
  {
    name: SocialPlatform.LINKEDIN,
    url: /linkedin\.com/,
  },
];

export const COOKIE_OPTIONS = {
  httpOnly: true,
  maxAge: 1000 * 60 * 60 * 24 * 365, // 1 year in seconds
  // sameSite: "none",
  // secure: true,
};

export const API_URL =
  process.env.NEXT_PUBLIC_API_URL ||
  `http${process.env.NODE_ENV === "development" ? "" : "s"}://${
    process.env.NEXT_PUBLIC_VERCEL_URL
  }/api`;
