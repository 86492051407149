"use client";

import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useRouter } from "next/navigation";
import { useMutation } from "@apollo/client";
import track from "@/utils/track";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setSavedPath,
  setSavedFollow,
} from "../../redux/slices/savedProgressSlice";
import Button from "../Button/Button";
import GoogleIcon from "../svgs/icons/Google";
import {
  CURRENT_USER_QUERY,
  GET_CLUSTERS_QUERY,
  GET_CLUSTER_QUERY,
  GOOGLE_SIGN_IN_MUTATION,
  GOOGLE_SIGN_UP_MUTATION,
  UPDATE_CLUSTER_MUTATION,
} from "../../apollo/queries";
import { AnalyticsEventName } from "../../types";
import GenericModal from "../GenericModal/GenericModal";
import TermsPrompt from "../TermsPrompt/TermsPrompt";

type UserDataForSignupShape = {
  firstName: String;
  lastName: String;
  email: String;
};

type ContinueWithGoogleButtonProps = {
  className?: string;
};

const ContinueWithGoogleButton = ({
  className,
}: ContinueWithGoogleButtonProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { savedPath, savedFollow } = useAppSelector(
    (state) => state.savedProgress,
  );

  // New account modal handling
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);
  const [signupError, setSignupError] = useState("");

  const [userDataForSignup, setUserDataForSignup] = useState(
    {} as UserDataForSignupShape,
  );

  const [googleSignUp] = useMutation(GOOGLE_SIGN_UP_MUTATION, {
    refetchQueries: [CURRENT_USER_QUERY],
  });

  const [updateCluster] = useMutation(UPDATE_CLUSTER_MUTATION, {
    refetchQueries: [
      GET_CLUSTERS_QUERY,
      {
        query: GET_CLUSTER_QUERY,
        variables: {
          input: {
            id: savedFollow,
          },
        },
      },
    ],
  });

  const [googleSignIn] = useMutation(GOOGLE_SIGN_IN_MUTATION, {
    refetchQueries: [CURRENT_USER_QUERY],
  });

  const closeModal = () => {
    setModalOpen(false);
  };

  // Get stored user values after Google auth attempt and sign up new user after ToU are accepted
  const handleNewUser = async () => {
    setSignupError("");
    setModalSubmit(true);

    const { firstName, lastName, email } = userDataForSignup;

    await googleSignUp({
      variables: {
        input: {
          firstName,
          lastName,
          email: email.toLowerCase(),
          authManagementType: "GOOGLE",
        },
      },
      onCompleted: async ({ googleSignUp: { user } }) => {
        if (savedFollow) {
          // TODO: might need to do same mutations as in FollowButton
          await updateCluster({
            variables: {
              input: {
                id: savedFollow,
                data: {
                  follow: true,
                },
              },
            },
          });

          dispatch(setSavedFollow(null));
        }

        // analytics
        track(AnalyticsEventName.GOOGLE_SIGNUP, {
          User: user?.id,
        });

        closeModal();
        setModalSubmit(false);
      },
      onError: () => {
        setSignupError("Something went wrong! Please try again later.");
      },
      update: (
        cache,
        {
          data: {
            googleSignUp: { user },
          },
        },
      ) => {
        // update apollo cache
        cache.writeQuery({
          query: CURRENT_USER_QUERY,
          data: {
            currentUser: user,
          },
        });
      },
    });
  };

  // Call api with google auth response and decide whether to login user or begin sign up process
  const handleGoogleLogin = async (tokenResponse) => {
    await googleSignIn({
      variables: {
        input: {
          token: tokenResponse,
        },
      },
      onCompleted: async ({ googleSignIn: { user, newUserData } }) => {
        if (savedFollow) {
          // TODO: might need to do same mutations as in FollowButton
          await updateCluster({
            variables: {
              input: {
                id: savedFollow,
                data: {
                  follow: true,
                },
              },
            },
          });

          dispatch(setSavedFollow(null));
        }

        if (user) {
          // analytics
          track(AnalyticsEventName.GOOGLE_LOGIN, {
            User: user.id,
          });
        }

        if (newUserData) {
          setUserDataForSignup(newUserData);
          setModalOpen(true);
        }

        if (savedPath) {
          router.push(savedPath);
          dispatch(setSavedPath(null));
        } else {
          router.push("/sparks");
        }
      },
      update: (
        cache,
        {
          data: {
            googleSignIn: { user, newUserData },
          },
        },
      ) => {
        // update apollo cache
        cache.writeQuery({
          query: CURRENT_USER_QUERY,
          data: {
            currentUser: user || newUserData,
          },
        });
      },
    });
  };

  // Call google auth api to begin process
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      handleGoogleLogin(tokenResponse);
    },
  });

  return (
    <>
      <Button
        className={className}
        variant="google"
        title="Continue with Google"
        onClick={login}
      >
        <GoogleIcon className="w-7 h-7 mr-2" />
      </Button>
      <GenericModal
        className="w-auto p-8"
        center
        isOpen={modalOpen}
        contentLabel=""
        close={closeModal}
      >
        <TermsPrompt
          closeModal={closeModal}
          handleNewUser={handleNewUser}
          modalSubmit={modalSubmit}
          signupError={signupError}
        />
      </GenericModal>
    </>
  );
};

export default ContinueWithGoogleButton;
