import { HTMLProps, MouseEvent, ReactNode } from "react";
import cn from "@/utils/cn";

export type ButtonProps = HTMLProps<HTMLButtonElement> & {
  variant?: string;
  title?: string;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  type?: "button" | "submit" | "reset";
  onClick?: (event?: MouseEvent) => void;
  "data-testid"?: string;
};

const Button = ({
  variant = "default",
  title,
  disabled = false,
  className,
  children,
  type = "button",
  onClick,
  "data-testid": dataTestId,
}: ButtonProps) => (
  <button
    className={cn(
      "bg-blue border-transparent border-solid border-2 flex items-center justify-center text-lg rounded text-white cursor-pointer disabled:bg-cancel disabled:text-grey disabled:cursor-not-allowed ",
      {
        "py-2 px-8": variant !== "link",
        "hover:enabled:bg-black hover:enabled:text-white hover:enabled:border-black":
          variant === "default" && !disabled,
        "border-0 m-0 no-underline text-center bg-transparent text-blue":
          variant === "link",
        "bg-transparent border-transparent p-0 rounded-none":
          variant === "wrap",
        "text-blue bg-light border-bluegrey hover:enabled:bg-black hover:enabled:text-white hover:enabled:border-black":
          variant === "google",
        [className as string]: !!className,
      },
    )}
    // eslint-disable-next-line react/button-has-type
    type={type}
    onClick={onClick}
    data-testid={dataTestId}
    disabled={disabled}
  >
    {children}
    {title}
  </button>
);

export default Button;
