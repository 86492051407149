"use client";

import { ChangeEvent, InputHTMLAttributes, useEffect, useRef } from "react";
import { ErrorMessage, useField } from "formik";
import useIsViewingSharedContent from "@/hooks/useIsViewingSharedContent";
import cn from "@/utils/cn";
import AsterixIcon from "../../../svgs/icons/Asterix";

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  helpText?: string;
  noErrorState?: boolean;
  initialFocus?: boolean;
  name: string;
  handleChange?: (e: ChangeEvent) => void;
  wrapperClassName?: string;
};

const TextInput = ({
  id,
  label,
  name,
  helpText,
  noErrorState,
  initialFocus = false,
  handleChange,
  className,
  wrapperClassName,
  ...props
}: TextInputProps) => {
  const [field, meta] = useField({ ...props, name });
  const inputEl = useRef<HTMLInputElement>(null);
  const { isViewingShared } = useIsViewingSharedContent();

  useEffect(() => {
    if (initialFocus) {
      inputEl.current?.focus();
    }
  }, [initialFocus]);

  const error = meta.touched && meta.error !== undefined;

  return (
    <div
      className={cn("w-full flex flex-col gap-y-3", {
        [wrapperClassName as string]: !!wrapperClassName,
      })}
    >
      {label ? (
        <label className="flex text-sm font-medium" htmlFor={id || name}>
          {label}
        </label>
      ) : null}
      {helpText ? (
        <div className="flex mb-2">
          <p className="text-sm text-grey m-0">{helpText}</p>
        </div>
      ) : null}
      <div className="relative">
        {!noErrorState && meta.touched && meta.error !== undefined ? (
          <AsterixIcon className="fill-red flex absolute top-1/2 w-2 h-2 -mt-1 -left-4 md:w-4 md:h-4 md:-mt-2 md:-left-8" />
        ) : null}
        <input
          className={cn(
            "relative text-lg rounded-lg border p-4 w-full placeholder:text-grey placeholder:font-light",
            {
              "border-red": error,
              "border-grey": !error,
              "bg-[#F4F5F8]": isViewingShared,
              [className as string]: !!className,
            },
          )}
          ref={inputEl}
          {...field} // eslint-disable-line react/jsx-props-no-spreading
          {...props} // eslint-disable-line react/jsx-props-no-spreading
          onChange={(e) => {
            // custom logic
            if (handleChange) {
              handleChange(e);
            }

            field.onChange(e);
          }}
        />
      </div>
      <ErrorMessage
        className="self-start text-sm text-red"
        name={name}
        component="div"
      />
    </div>
  );
};

export default TextInput;
